.footer {
  margin: $dp-baseline * 6 0 0;
  padding: $dp-baseline * 10 0 $dp-baseline * 6;
  background-color: $dp-color-grey;

  h5 {
    color: $dp-color-yellow;
    font-weight: 300;

    &::after {
      display: block;
      position: relative;
      bottom: -4px;
      width: 100px;
      height: 2px;
      background-color: currentColor;
      content: "";
    }
  }

  p {
    color: $dp-color-light;
  }

  .menu {
    &__item {
      display: flex;
    }

    &__info {
      float: none;
      background-color: transparent;
      color: $dp-color-light;
    }

    &__link {
      padding: $dp-baseline 0;
      font-size: 1rem;
      text-transform: capitalize;

      &:hover {
        background-color: transparent;
        color: $dp-color-brand;
      }

      &.is-active {
        background-color: transparent;
        color: $dp-color-brand;
      }
    }
  }

  .social {
    margin-top: $dp-baseline * 8;
  }

  .phone {
    margin-top: $dp-baseline * 4;
    margin-bottom: $dp-baseline;
    color: $dp-color-light;
    font-size: $dp-font-size-xl;
  }

  .email {
    &:hover {
      color: $dp-color-yellow;
    }
  }

  .copyright {
    width: 100%;
    margin-top: $dp-baseline * 8;
    text-align: center;

    a,
    p {
      font-size: $dp-font-size-sm;
      font-weight: 300;
    }

    a {
      color: $dp-color-yellow;

      &:hover {
        color: $dp-color-brand;
      }
    }
  }
}
