@import "utilities/variables";
@import "utilities/mixins";

@import "components/button";
@import "components/card";
@import "components/grid";
@import "components/typography";

html {
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  line-height: 1.4;
}

body {
  margin: 0 auto;
  background-color: $dp-color-grey-lighter;
}

.big {
  color: $dp-color-brand;
  font-weight: $dp-font-bold;
}

.color-brand {
  color: $dp-color-brand;
}

.pt-0 {
  padding-top: 0 !important;
}

.mt-5 {
  margin-top: $dp-baseline * 5 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mr-2 {
  margin-right: $dp-baseline * 2;
}

.main {
  margin: 0 auto;
}

.container {
  width: 1024px;
  margin: auto;
}

.content {
  margin: auto;
  padding: $dp-baseline * 2 $dp-baseline * 4;
}

.box {
  flex-direction: column;
  border-radius: $dp-border-radius;
  background-color: #fff;
}

canvas {
  position: fixed;
  top: 0;
  pointer-events: none;
}

// Layout
@import "layout/header";
@import "layout/menu";
@import "layout/footer";

// Pages
@import "pages/home";
@import "pages/contacts";
