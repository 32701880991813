
h2 {
  font-size: 2rem;
  font-weight: 300;
}

h4 {
  color: $dp-color-grey;
  font-size: 1.5rem;
  font-weight: $dp-font-regular;
}

h5 {
  color: $dp-color-grey;
  font-size: 1.25rem;
  font-weight: $dp-font-bold;
}

h6 {
  color: $dp-color-brand;
  font-size: 1.125rem;
  font-weight: $dp-font-bold;
}

p {
  font-weight: 300;
  font-size: $dp-font-size-base;
  line-height: 1.5;
}

a {
  color: $dp-color-brand;
  text-decoration: none;
}

small {
  color: $dp-color-brand;
  font-size: $dp-font-size-sm;
  font-weight: $dp-font-bold;
  text-transform: uppercase;
}
