// Color
$dp-color-brand: #3d9adb;
$dp-color-brand-light: #4981fd;
$dp-color-text: #181e24;

$dp-color-grey-lighter: #ebeae9;
$dp-color-grey: #28313a;
$dp-color-light: #fff;
$dp-color-yellow: #f7ca85;

$dp-baseline: 6px;

$dp-font-size-xs: .625rem; // 10px
$dp-font-size-sm: .8571rem; // 12px
$dp-font-size-base: 1rem; // (base) 14px
$dp-font-size-md: 1.143rem; // 16px
$dp-font-size-lg: 1.286rem; // 18px
$dp-font-size-xl: 1.571rem; // 22px

$dp-border-radius: 2px; // 22px

$dp-font-regular: 400;
$dp-font-bold: 700;

