.map {
  display: block;
  width: 100%;
}

.btn {
  margin-left: 0 !important;
}

.is-disabled {
  pointer-events: none;
  opacity: .5;
}

.schedule {
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    padding: 0;
    list-style: none;
  }
}

.social {
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    padding: 0;
    list-style: none;

    a {
      padding: $dp-baseline $dp-baseline * 3;
      transition: all .3s;
      border: 1px solid $dp-color-brand;
      border-radius: $dp-border-radius;
      font-size: $dp-font-size-xl;

      &:hover {
        background-color: $dp-color-brand;
        color: $dp-color-light;
      }
    }
  }
}
