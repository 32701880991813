.slider {
  position: relative;

  &__btn {
    position: absolute;
    top: 50%;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 0;
    font-size: 1rem;
    color: $dp-color-brand;
    transform: translateY(-50%);
    transition: background-color 0.3s, color 0.3s;
    cursor: pointer;

    &:hover {
      background-color: $dp-color-brand;
      color: #fff;
    }

    &:focus {
      outline: none;
    }
  }
}

.prev {
  left: 6px;
}

.next {
  right: 6px;
}

.siema {
  img {
    width: 100%;
  }
}
