.grid {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  margin-right: -$dp-baseline * 2;
  margin-left: -$dp-baseline * 2;
}

.grid__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 25%;
  margin: 0;
  padding: $dp-baseline * 2;
}

.grid--x5 .grid__item {
  width: 20%;
}
