.nav {
  margin: $dp-baseline * 2 0;
  border-radius: 2px;
  background: $dp-color-grey;
}

.menu {
  display: block;
  padding: 0;
  background-color: transparent;
  list-style: none;
  text-decoration: none;

  &__item {
    display: inline-flex;
    color: $dp-color-light;
    list-style: none;
    text-align: center;
  }

  &__info {
    float: right;
    background-color: $dp-color-yellow;
    color: $dp-color-text;
  }

  &__link {
    display: block;
    position: relative;
    padding: $dp-baseline * 2 $dp-baseline * 4;
    transition: background-color .3s, color .3s;
    color: currentColor;
    font-size: $dp-font-size-sm;
    font-weight: $dp-font-regular;
    text-decoration: none;
    text-transform: uppercase;

    &.is-active {
      background-color: $dp-color-light;
      color: $dp-color-grey;
    }

    &:hover {
      background-color: $dp-color-light;
      color: $dp-color-grey;
    }
  }
}
