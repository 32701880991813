.btn {
  display: block;
  position: relative;
  height: $dp-baseline * 6;
  margin: 0 $dp-baseline;
  padding: 0 $dp-baseline * 3;
  float: left;
  transition: color .3s ease;
  border: 0;
  border-radius: $dp-border-radius;
  background: none;
  color: inherit;
  font-size: .75rem;
  font-weight: normal;
  letter-spacing: .4px;
  line-height: $dp-baseline * 6;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;
  vertical-align: middle;
  z-index: 0;
}

.btn--large {
  width: 120px;
}

.btn--base {
  background-color: $dp-color-brand;
  color: $dp-color-light;
  overflow: hidden;
}

.btn--base::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 150%;
  height: 100%;
  transform: rotate3d(0, 0, 1, -45deg) translate3d(0, -3em, 0);
  transform-origin: 0% 100%;
  transition: transform .3s, opacity .3s, background-color .3s;
  background-color: $dp-color-brand-light;
  content: "";
  z-index: -1;
}

.btn--base:hover {
  color: $dp-color-light;
}

.btn--base:hover::before {
  transform: rotate3d(0, 0, 1, 0deg);
  transition-timing-function: cubic-bezier(.2, 1, .3, 1);
  background-color: $dp-color-grey;
  opacity: 1;
}
