.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 260px;
  padding: $dp-baseline * 4 0;
  transform: none;
  transition: all .3s;
  border: 1px solid rgba($dp-color-text, .1);
  border-radius: $dp-border-radius;
  cursor: pointer;

  &:hover {
    transform: scale(1.05, 1.05);
    border: 1px solid $dp-color-light;
    box-shadow: -10px 10px 50px 0 rgba($dp-color-text, .1);
  }

  &--slimborder:hover {
    transform: none;
    border: 1px solid rgba($dp-color-text, .1);
    box-shadow: none;
  }

  &__image {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 80%;
      object-fit: cover;
    }
  }

  &__body {
    color: $dp-color-grey;
    text-align: center;

    p {
      font-size: 1rem;
      font-weight: $dp-font-regular;
    }

    strong {
      font-weight: $dp-font-bold;
    }
  }

  &__price {
    font-size: 1.4em;
  }

  &__action {
    margin-top: $dp-baseline * 4;
  }
}
