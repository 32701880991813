.header {
  width: 100%;
  height: auto;
}

.logo {
  display: block;
  width: 340px;
  margin: $dp-baseline * 4 auto;

  img {
    width: 100%;
  }
}
